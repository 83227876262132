import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";
import { Paper, makeStyles } from "@material-ui/core";
import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInputCustom/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtonsCustom";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TagsContainer } from "../TagsContainer";
import { socketConnection } from "../../services/socket";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		height: "100%",
		position: "relative",
		overflow: "hidden",
	},

	mainWrapper: {
		flex: 1,
		height: "100%",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderLeft: "0",
		marginRight: -drawerWidth,
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},

	mainWrapperShift: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
}));

const Ticket = () => {
	const { ticketId } = useParams();
	const history = useHistory();
	const classes = useStyles();
	const { user } = useContext(AuthContext);

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [contact, setContact] = useState({});
	const [ticket, setTicket] = useState({});

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTicket = async () => {
				try {
					const { data } = await api.get("/tickets/u/" + ticketId);
					setContact(data.contact);
					setTicket(data);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchTicket();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [ticketId, user, history]);

	useEffect(() => {
		const companyId = localStorage.getItem("companyId");
		const socket = socketConnection({ companyId });

		if (ticket.id) {
			socket.emit("joinChatBox", `${ticket.id}`);

			const handleTicketUpdate = (data) => {
				if (data.action === "update") {
					setTicket(data.ticket);
				}
				if (data.action === "delete") {
					toast.success("Ticket deleted successfully.");
					history.push("/tickets");
				}
			};

			const handleContactUpdate = (data) => {
				if (data.action === "update") {
					setContact((prevState) => {
						if (prevState.id === data.contact?.id) {
							return { ...prevState, ...data.contact };
						}
						return prevState;
					});
				}
			};

			socket.on(`company-${companyId}-ticket`, handleTicketUpdate);
			socket.on(`company-${companyId}-contact`, handleContactUpdate);

			return () => {
				socket.off(`company-${companyId}-ticket`, handleTicketUpdate);
				socket.off(`company-${companyId}-contact`, handleContactUpdate);
				socket.emit("leaveChatBox", `${ticket.id}`);
			};
		}
	}, [ticket.id, history]);

	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	const renderTicketInfo = () => {
		if (ticket.user !== undefined) {
			return (
				<TicketInfo
					contact={contact}
					ticket={ticket}
					onClick={handleDrawerOpen}
				/>
			);
		}
	};

	const renderMessagesList = () => {
		return (
			<>
				<MessagesList
					ticket={ticket}
					ticketId={ticket.id}
					isGroup={ticket.isGroup}
				/>
				<MessageInput ticketId={ticket.id} ticketStatus={ticket.status} />
			</>
		);
	};

	return (
		<div className={classes.root} id="drawer-container">
			<Paper
				variant="outlined"
				elevation={0}
				className={clsx(classes.mainWrapper, {
					[classes.mainWrapperShift]: drawerOpen,
				})}
			>
				<TicketHeader loading={loading}>
					{renderTicketInfo()}
					<TicketActionButtons ticket={ticket} />
				</TicketHeader>
				<Paper>
					<TagsContainer ticket={ticket} />
				</Paper>
				<ReplyMessageProvider>{renderMessagesList()}</ReplyMessageProvider>
			</Paper>
			<ContactDrawer
				open={drawerOpen}
				handleDrawerClose={handleDrawerClose}
				contact={contact}
				loading={loading}
				ticket={ticket}
			/>
		</div>
	);
};

export default Ticket;
