import React, { useState } from 'react';
import { TextField, Button, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { red } from '@material-ui/core/colors';
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        margin: 'auto',
    },
});

export function QueueTrigger({ 
    triggerId, 
    triggerData = undefined,  
    onSave = ()=>{},
    onClose = ()=>{}
}) {
    const classes = useStyles();
    const [formData, setFormData] = useState(triggerData ?? {
        description: "",
        isRegex: false,
        name: "",
        queueId: null,
        triggerText: "",
    });

    const [isMatch, setIsMatch] = useState(false); // Estado para armazenar se a expressão regular corresponde ao texto

    const [openDialog, setOpenDialog] = useState(false); // Estado para controlar se a caixa de diálogo está aberta
    const [formError, setFormError] = useState(""); // Estado para armazenar erros de formulário
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSubmit = async () => {
        if (!formData.triggerText.trim()) { // Verifica se o campo "Trigger Text" está vazio
            setFormError("Trigger Text is required.");
            return;
        }
        try {
            if(formData.id != undefined && formData.id > 0){
                const { data } = await api.put(`/queue-triggers/${formData.id}`,{
                    ...formData
                });
            }else{
                const { data } = await api.post("/queue-triggers",{
                    ...formData
                });
            }
            onSave()
        } catch (e) {
            console.log(e)
            toastError(e);
            return
        }
    };

    return (
        <form className={classes.form}>
            <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
            />
            <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                multiline
                rows={4}
                required
            />
            <TextField
                label="Trigger Text"
                name="triggerText"
                value={formData.triggerText}
                onChange={handleChange}
                multiline
                rows={4}
                required
                error={!!formError} // Aplica estilo de erro se houver erro
                helperText={formError} // Mostra a mensagem de erro
            />
            <FormControlLabel
                control={<Checkbox checked={formData.isRegex} onChange={handleChange} name="isRegex" />}
                label="Is Regex"
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
            <Button variant="contained" color="secondary" onClick={onClose}>
                Cancel
            </Button>
        </form>
    );
}
