import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import { Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, Modal, Backdrop, Fade } from '@material-ui/core';
import api from "../../services/api";
import { QueueTrigger } from '../QueueTrigger';

const useStyles = makeStyles((theme) => ({
    modal: {
      // Defina a largura desejada aqui, por exemplo, 80% do tamanho da tela
      width: '80%',
      maxWidth: '1300px', // Defina um valor máximo para a largura, se necessário
      margin: 'auto', // Centraliza o modal horizontalmente
    },
    modalContent: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
    },
}));

export function QueueTriggerList({ queueId}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false); // Estado para controlar a abertura do modal
    const [selectedTrigger, setSelectedTrigger] = useState(null); // Estado para armazenar o gatilho selecionado
    const [Triggers, setTriggers] = useState([]); // Estado para armazenar os detalhes do trigger

    const fetchTriggerDetails = async () => {
        setOpen(false);
        setSelectedTrigger(null)
        try {
            if(queueId == undefined){
                setTriggers([]);
            }else{
                const { data } = await api.request({
                    url: "/queue-triggers",
                    method: "GET",
                    params: { queueId },
                });
                setTriggers(data); // Definir os detalhes do trigger no estado
            }
        } catch (e) {
            setTriggers([]);
            toastError(e);
        }
        
    };

    useEffect(()=>{
        fetchTriggerDetails()
    },[queueId])

    const handleOnSave = ()=>{
        fetchTriggerDetails()
        setOpen(false)
        setSelectedTrigger(null)
    }

    const handleTrigger = (triggerId) => {
        setSelectedTrigger({ id: triggerId }); // Definir o trigger selecionado
        setOpen(true); // Abrir o modal
    };

    const handleEdit = (triggerId) => {
        const selected = Triggers.find((trigger) => trigger.id === triggerId);
        setSelectedTrigger(selected);
        setOpen(true);
    };

    const handleDelete = async(triggerId)=>{
        setOpen(false)
        const { data } = await api.delete(`/queue-triggers/${triggerId}`);
        fetchTriggerDetails()
    }

    const handleAdd = async () => {
        // try {
        //     const { data } = await api.post("/queue-triggers",{
        //         queueId: queueId,
        //         name: "",
        //         description: "",
        //         triggerText: "",
        //         isRegex: false,
        //     })
        //     fetchTriggerDetails(); // Definir os detalhes do trigger no estado
        // } catch (e) {
        //     toastError(e);
        // }
        setSelectedTrigger({
            queueId: queueId,
            name: "",
            description: "",
            triggerText: "",
            isRegex: false,
        })
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedTrigger(null)
    };

    return (
        <Paper>
            <Table className={classes.table} aria-label="QueueTrigger table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Triggers.map((trigger) => (
                        <>
                            <TableRow key={trigger.id}>
                                <TableCell>{trigger.name}</TableCell>
                                <TableCell>{trigger.description}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" color="primary" onClick={() => handleEdit(trigger.id)}>
                                        Edit
                                    </Button>
                                    <Button variant="outlined" color="primary" onClick={() => handleDelete(trigger.id)}>
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </>
                    ))}
                    <Button variant="outlined" color="primary" onClick={() => handleAdd()}>
                        ADD TRIGGER
                    </Button>
                </TableBody>
            </Table>

            {/* Modal para exibir detalhes do gatilho */}
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <div className={classes.modalContent}>
                        {selectedTrigger && 
                            (<QueueTrigger triggerId={selectedTrigger.id} triggerData={selectedTrigger} onSave={handleOnSave} onClose={()=>{
                                setOpen(false)
                                setSelectedTrigger(null)
                            }}></QueueTrigger>)
                        }
                    </div>
                </Fade>
            </Modal>
        </Paper>
    );
}
