import openSocket from "socket.io-client";
import { isObject } from "lodash";
import toastError from "../errors/toastError";

let socketInstance = null;

export function socketConnection(params) {
	if (socketInstance) {
		return socketInstance;
	}

	let userId = null;
	if (localStorage.getItem("userId")) {
		userId = localStorage.getItem("userId");
	}

	socketInstance = openSocket(process.env.REACT_APP_BACKEND_URL, {
		query: isObject(params) ? { ...params, userId } : { userId },
		reconnection: true, // Ativa a reconexão automática
		reconnectionAttempts: Infinity, // Número infinito de tentativas de reconexão
		reconnectionDelay: 1000, // Tempo entre as tentativas de reconexão (em ms)
	});

	// Listener para evento de desconexão
	socketInstance.on("disconnect", (reason) => {
		console.log("Socket desconectado:", reason);
		toastError("Sem Conexão ao servico");
		// O socket.io já tentará reconectar automaticamente devido ao reconnection: true
	});

	socketInstance.on("connect", () => {});

	// Listener para evento de reconexão
	socketInstance.on("reconnect", (attemptNumber) => {
		toastError("Conectado ao serviço");
		console.log("Socket reconectado após", attemptNumber, "tentativas");
	});

	// Listener para evento de falha na reconexão
	socketInstance.on("reconnect_failed", () => {
		toastError("Falha ao reconectar");
		console.log("Falha ao tentar reconectar");
	});

	return socketInstance;
}
